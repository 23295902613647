import { ImageDetailModel, ImageModel, images } from "./data";
import { replaceLocation } from "./router";
import { strictQuerySelector } from "./utilities";

export let fullImage: ImageModel | ImageDetailModel | null = null;

let fullImageIndex: number | null = null;
let oldFullImageIndex: number | null = null;
let currentDetailEl: HTMLDivElement;

const fullImageEl = strictQuerySelector<HTMLDivElement>(document, '#full-image');
const nextImageEl = strictQuerySelector<HTMLDivElement>(document, '#next-image');
const prevImageEl = strictQuerySelector<HTMLDivElement>(document, '#prev-image');
const fullImageCaptionEl = strictQuerySelector<HTMLDivElement>(document, '#full-image-caption');
const closeImageEl = strictQuerySelector<HTMLDivElement>(document, '#close-full-image');
const detailsContainerEl = strictQuerySelector<HTMLDivElement>(document, '#full-image-details-container');
const fullImageContainerEl = strictQuerySelector<HTMLDivElement>(document, '#full-image-container');

closeImageEl.addEventListener('click', replaceLocation.bind(null, './'));

  nextImageEl.addEventListener('click', function () {
    const index = fullImageIndex;

    if (typeof index === 'number') {
      const nextImage = images[index + 1] || images[0];

      clearFullImageSrc();
      replaceLocation(nextImage.slug);
    }
  });

  prevImageEl.addEventListener('click', function () {
    const index = fullImageIndex;
    const lastIndex = images.length - 1;

    if (typeof index === 'number') {
      const prevImage = images[index - 1] || images[lastIndex];

      clearFullImageSrc();
      replaceLocation(prevImage.slug);
    }
  });

  fullImageEl.addEventListener('mouseenter', function () {
    fullImageCaptionEl.className = 'shown';
  });

  fullImageEl.addEventListener('mouseleave', function () {
    fullImageCaptionEl.className = '';
  });

export function setFullImage (image: ImageModel | ImageDetailModel) {

  document.body.classList.add('full-image');

  fullImage = image;

  if (!fullImage.detail) {
    fullImageIndex = images.indexOf(fullImage);
  }

  setFullImageRect(image);

  const placeHolderImg = strictQuerySelector<HTMLImageElement>(fullImageEl, 'img.placeholder-image');
  const actualImg = strictQuerySelector<HTMLImageElement>(fullImageEl, 'img.actual-image');

  placeHolderImg.src = 'images/tiny/' + image.imageSrc;

  placeHolderImg.onload = function () {

    // Portrait small width
    let smallWidth = 500;

    if (image.pixelDimensions[0] >= image.pixelDimensions[1]) {
      const ratio = 500 / image.pixelDimensions[1];
      smallWidth = Math.round( image.pixelDimensions[0] * ratio );
    }

    if (window.innerWidth > smallWidth) {
      actualImg.src = 'images/large/' + image.imageSrc;
    } else {
      actualImg.src = 'images/small/' + image.imageSrc;
    }

    actualImg.onload = function () {
      actualImg.style.display = 'block';
    };

  };

  // Only update caption and details bar when top level index changes
  if (!image.detail && fullImageIndex !== oldFullImageIndex) {

    const text = [image.title, image.dimensions, image.medium]
      .filter(function (info) {
        return info;
      })
      .join('  |  ');

    fullImageCaptionEl.textContent = text;

    if (showDetails()) {
      setImageDetails(image);
    } else {
      unsetImageDetails();
    }
  }

  oldFullImageIndex = fullImageIndex;
}

export function unsetFullImage () {
  fullImage = null;
  fullImageIndex = null;
  oldFullImageIndex = null;

  clearFullImageSrc();
  unsetImageDetails();

  document.body.classList.remove('full-image');
}

export function setFullImageRect (fullImage: ImageModel | ImageDetailModel) {

  const imgPadding = 20;
  const prevNextDim = 50;
  const prevNextPadding = prevNextDim + (imgPadding * 4);
  const detailsPadding = showDetails() ? 100 : 0;

  const containerWidth = fullImageContainerEl.clientWidth;
  const containerHeight = fullImageContainerEl.clientHeight;

  // Constraint for landscape window
  const maxImgWidth = containerWidth - (prevNextPadding * 2);

  // Constraint for portrait window
  const maxImgHeight = 
    containerHeight - detailsPadding - (prevNextPadding + imgPadding * 4);

  let imgHeight: number, imgWidth: number, scale: number;
  let imgTop: number, imgLeft: number, prevNextTop: number, detailsTop: number;

  if (containerWidth > containerHeight) { // Landscape window

    imgHeight = containerHeight - detailsPadding - (imgPadding * 2);
    scale = imgHeight / fullImage.pixelDimensions[1];
    imgWidth = scale * fullImage.pixelDimensions[0];

    // Constrain width
    if (imgWidth > maxImgWidth) {
      imgWidth = maxImgWidth;
      scale = imgWidth / fullImage.pixelDimensions[0];
      imgHeight = scale * fullImage.pixelDimensions[1];
    }

    imgTop = ((containerHeight - detailsPadding) * 0.5) - (imgHeight * 0.5);
    imgLeft = (containerWidth * 0.5) - (imgWidth * 0.5);
    prevNextTop = containerHeight * 0.5;

  } else { // Portrait window

    imgWidth = containerWidth - imgPadding * 2;
    scale = imgWidth / fullImage.pixelDimensions[0];
    imgHeight = scale * fullImage.pixelDimensions[1];

    // Constrain height
    if (imgHeight > maxImgHeight) {
      imgHeight = maxImgHeight;
      scale = imgHeight / fullImage.pixelDimensions[1];
      imgWidth = scale * fullImage.pixelDimensions[0];
    }

    imgTop = (imgPadding * 4) + (maxImgHeight * 0.5) - (imgHeight * 0.5);
    imgLeft = containerWidth * 0.5 - imgWidth * 0.5;
    prevNextTop = containerHeight - (prevNextDim + imgPadding);
  }

  // Always render details bar right below image when shown
  if (showDetails()) {
    detailsTop = imgTop + imgHeight + imgPadding;
  } else {
    detailsTop = containerHeight;
  }

  fullImageEl.style.width = imgWidth + 'px';
  fullImageEl.style.height = imgHeight + 'px';
  fullImageEl.style.left = imgLeft + 'px';
  fullImageEl.style.top = imgTop + 'px';

  detailsContainerEl.style.top = detailsTop + 'px';

  prevImageEl.style.width = prevNextDim + 'px';
  prevImageEl.style.height = prevNextDim + 'px';
  prevImageEl.style.top = prevNextTop + 'px';
  prevImageEl.style.left = imgPadding * 2 + 'px';

  nextImageEl.style.width = prevNextDim + 'px';
  nextImageEl.style.height = prevNextDim + 'px';
  nextImageEl.style.top = prevNextTop + 'px';
  nextImageEl.style.right = imgPadding * 2 + 'px';

  return [imgWidth, imgHeight];
}

function clearFullImageSrc () {
  const placeHolderImg = strictQuerySelector<HTMLImageElement>(fullImageEl, 'img.placeholder-image');
  const actualImg = strictQuerySelector<HTMLImageElement>(fullImageEl, 'img.actual-image');

  placeHolderImg.src = '';
  actualImg.src = '';
  actualImg.style.display = '';
}

function setCurrentImageDetail (detailEl: EventTarget | ChildNode | null) {
  if (!(detailEl instanceof HTMLDivElement)) {
    return;
  }

  if (currentDetailEl) {
    currentDetailEl.classList.remove('current');
  }

  currentDetailEl = detailEl;

  currentDetailEl.classList.add('current');
}

function setImageDetails (image: ImageModel) {
  let details: (ImageModel | ImageDetailModel)[] = [image];
  details = details.concat(image.details || []);

  while (detailsContainerEl.firstChild) {
    detailsContainerEl.removeChild(detailsContainerEl.firstChild);
  }

  details.forEach(function (detail) {
    const detailEl = document.createElement('div');
    
    detailEl.classList.add('detail-container'); 
    detailEl.style.backgroundImage = 
      'url(images/small/' + detail.imageSrc + ')';

    detailEl.addEventListener('click', function (event) {
      clearFullImageSrc();
      setFullImage(detail);
      setCurrentImageDetail(event.target);
    });

    detailsContainerEl.appendChild(detailEl);
  });

  setCurrentImageDetail(detailsContainerEl.firstChild);

  detailsContainerEl.classList.add('shown');
}

function unsetImageDetails () {
  detailsContainerEl.classList.remove('shown');

  while (detailsContainerEl.firstChild) {
    detailsContainerEl.removeChild(detailsContainerEl.firstChild);
  }
}

function showDetails () {
  if (!fullImage) {
    return false;
  } else if (fullImage.detail) {
    return true;
  } else if (
    !fullImage.detail &&
    Array.isArray(fullImage.details) && 
    fullImage.details.length > 0
  ) {
    return true;
  } else {
    return false;
  }
}
import { DataModel, images } from "./data";
import { buildImageRow, checkThreshold, setGalleryState, updateImageRow } from "./gallery";
import { initializeLocation } from "./router";
import { debounce, strictQuerySelector } from "./utilities";
import { fullImage, setFullImage, setFullImageRect, unsetFullImage } from "./full-image";

import "./index.css";

const workLinkEl = strictQuerySelector<HTMLAnchorElement>(document, '#work-link');
const cvLinkEl = strictQuerySelector<HTMLAnchorElement>(document, '#cv-link');

function setCurriculumVitae () {
  document.body.classList.add('curriculum-vitae');
}

function unsetCurriculumVitae () {
  document.body.classList.remove('curriculum-vitae');
}

function setCurrentLink (location: string) {
  if (location === 'curriculum-vitae') {

    workLinkEl.classList.remove('current');
    cvLinkEl.classList.add('current');

  } else {

    workLinkEl.classList.add('current');
    cvLinkEl.classList.remove('current');

  }
}

function initialize () {

  processData();
  checkThreshold();
  setGalleryState();

  // Make a new array for buildImageRow consumption
  buildImageRow(images.slice());

  // Initialize router
  initializeLocation(); 

  const onResize = debounce(function () {

    checkThreshold(function () {
      setGalleryState();
      updateImageRow(images.slice());
    });

    if (fullImage) {
      setFullImageRect(fullImage);
    }

  }, 250);

  window.addEventListener('resize', onResize);
}

function processData () {
  const imageDataElement = strictQuerySelector(document, '#image-data');
  const dataText = imageDataElement.textContent || ''
  const data: DataModel = window.JSON.parse(dataText);

  data.images.filter(function (image) {
    if (!image.hide) {
      images.push(image);
    }
  });

  images.forEach(function (image, index) {
    image._id = index + 1;
    image.columnSpan = image.columnSpan || 1;
    image.detail = false;
    image.details = image.details || [];
    image.hide = image.hide || false;

    if (Array.isArray(image.details)) {
      image.details.forEach(function (detail) {
        detail.detail = true;
      });
    }
  });
}

window.addEventListener('locationchange', function (e) {
  if (!(e instanceof CustomEvent)) {
    return;
  }

  const newLocation = e.detail.newLocation;
  let newImage;

  for (let i = 0; i < images.length; i++) {
    if (images[i].slug === newLocation) {
      newImage = images[i];
      break;
    }
  }

  if (newImage) {
    
    unsetCurriculumVitae();
    setFullImage(newImage);

    setCurrentLink(newLocation);

  } else if (newLocation === 'curriculum-vitae') {

    unsetFullImage();
    setCurriculumVitae();

    setCurrentLink(newLocation);

  } else {

    unsetFullImage();

    setCurrentLink(newLocation);

  }
});

initialize();
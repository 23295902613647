export function strictQuerySelector<T extends HTMLElement> (context: ParentNode, selector: string) {
  const result = context.querySelector<T>(selector);

  if (!result) {
    throw new Error(`No element found for selector ${ selector }`);
  }

  return result;
}

export function debounce <T>(func: () => void, wait: number) {
  let timeout: number | undefined;

  return function (this: T) {

    clearTimeout(timeout);
    
    timeout = window.setTimeout(() => {
      timeout = undefined;
      func.call(this);
    }, wait);
  };

}
export interface ImageDetailModel {
  imageSrc: string;
  pixelDimensions: [number, number];
  detail: true;
}

export interface ImageModel {
  _id: number;
  title: string;
  slug: string;
  medium: string;
  dimensions: string;
  pixelDimensions: [number, number];
  tags: string[];
  imageSrc: string;
  hide: boolean;
  columnSpan: number;
  details: ImageDetailModel[];
  detail: false;
}

export interface DataModel {
  images: ImageModel[];
}

export const images: ImageModel[] = [];
import { ImageModel, images } from "./data";
import { replaceLocation } from "./router";
import { strictQuerySelector } from "./utilities";

const titleBlockHeight = 15;
const thresholds = [440, 750, 900, 1120, Infinity];
const smallThreshold = 500;

let threshold = 0;
let columnCount = 0;
let columnWidth: () => number = function () { return 0; };
let columnHeights: number[] = [];
let itemMargin = 0;

const containerEl = strictQuerySelector<HTMLUListElement>(document, '#images-container');
const cvEl = strictQuerySelector<HTMLDivElement>(document, '#curriculum-vitae');

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {

    if (entry.isIntersecting) {
      const target = entry.target as HTMLElement;
      setImageSrc(target);
      observer.unobserve(target);
    }

  });
});

export function checkThreshold (onChange?: () => void) {
  for (let i = 0; i < thresholds.length; i++) {
    if (window.innerWidth < thresholds[i]) {

      if (i !== threshold) {
        threshold = i;

        if (typeof onChange === 'function') {
          onChange();
        }
      }

      break;
    }
  }
}

export function setGalleryState () {

  if (threshold === 0) {

    columnCount = 1;
    columnWidth = function () {
      return window.innerWidth - itemMargin * 2;
    };
    itemMargin = 20;
  } else if (threshold === 1) {

    columnCount = 1;
    columnWidth = function () { return 400; };
    itemMargin = 20;
  } else if (threshold === 2) {

    columnCount = 2;
    columnWidth = function () { return 350; };
    itemMargin = 17;
  } else if (threshold === 3) {

    columnCount = 3;
    columnWidth = function () { return 275; };
    itemMargin = 13;
  } else {

    columnCount = 4;
    columnWidth = function () { return 250; };
    itemMargin = 12;
  }

  // Initialize columnHeights storage
  columnHeights = [];

  for (let i = 0; i < columnCount; i++) {
    columnHeights[i] = itemMargin;
  }

  const containerWidth =
    columnCount * (columnWidth() + itemMargin * 2);

  containerEl.style.width = containerWidth + 'px';
  cvEl.style.width = containerWidth + 'px';
}

export function updateImageRow (images: ImageModel[]) {

  if (images.length === 0) {
    return;
  }

  const rowImages = getRowImages(images); // Modifies images array
  const row = getBestRow(rowImages);

  let columnIndex = 0;

  row.forEach(function (image) {
    
    const parentColumnIndices = getParentColumnIndices(image, columnIndex);
    const lowestColumnIndex = getLowestColumnIndex(parentColumnIndices);
    const position = getPosition(columnIndex, lowestColumnIndex);
    const dimensions = getScaledDimensions(image);
    const height = getElementOuterHeight(dimensions[1]);

    updateParentColumnHeights(parentColumnIndices, lowestColumnIndex, height);

    updateImage(image, position, dimensions);

    columnIndex += parentColumnIndices.length;
  });

  updateImageRow(images);
}

function updateImage (
  image: ImageModel, 
  position: [number, number], 
  dimensions: [number, number]
) {
  const element = strictQuerySelector(document, '#image-' + image._id);
  const imgContainer = strictQuerySelector<HTMLDivElement>(element, '.image-container');
  const titleBlock = strictQuerySelector<HTMLDivElement>(element, '.title-block');

  setImageStyles(element, imgContainer, titleBlock, position, dimensions);
}

export function buildImageRow (images: ImageModel[]) {

  if (images.length === 0) {
    return;
  }

  const rowImages = getRowImages(images); // Modifies images array
  const row = getBestRow(rowImages);

  let columnIndex = 0;

  row.forEach(function (image) {

    const parentColumnIndices = getParentColumnIndices(image, columnIndex);
    const lowestColumnIndex = getLowestColumnIndex(parentColumnIndices);
    const position = getPosition(columnIndex, lowestColumnIndex);
    const dimensions = getScaledDimensions(image);
    const height = getElementOuterHeight(dimensions[1]);

    updateParentColumnHeights(parentColumnIndices, lowestColumnIndex, height);

    buildImage(image, position, dimensions);

    columnIndex += parentColumnIndices.length;
  });

  buildImageRow(images);
}

function buildImage (
  image: ImageModel, 
  position: [number, number], 
  dimensions: [number, number]
) {
  const element = document.createElement('li');
  element.id = 'image-' + image._id;

  const emphasisBlock = document.createElement('div');
  emphasisBlock.className = 'emphasis-block';

  const imgContainer = document.createElement('div');
  imgContainer.className = 'image-container';

  const placeholderImg = document.createElement('img');
  placeholderImg.className = 'placeholder-image';

  const actualImg = document.createElement('img');
  actualImg.className = 'actual-image';
  actualImg.style.display = 'none';

  const titleBlock = document.createElement('div');
  titleBlock.className = 'title-block';

  const title = document.createElement('h1');
  title.textContent = image.title;

  imgContainer.appendChild(placeholderImg);
  imgContainer.appendChild(actualImg);

  titleBlock.appendChild(title);

  element.appendChild(emphasisBlock);
  element.appendChild(imgContainer);
  element.appendChild(titleBlock);

  containerEl.appendChild(element);

  setImageStyles(element, imgContainer, titleBlock, position, dimensions);

  element.addEventListener('click', replaceLocation.bind(null, image.slug));
  observer.observe(element);
}

function setImageSrc (element: HTMLElement) {

  const match = element.id.match(/^image-(\d+)$/);

  if (match) {
    const id = parseInt(match[1], 10);
    const image = images.find(image => image._id === id);

    if (image) {
      const placeholderImg = strictQuerySelector<HTMLImageElement>(element, 'img.placeholder-image');
      const actualImg = strictQuerySelector<HTMLImageElement>(element, 'img.actual-image');

      placeholderImg.onload = () => {

        // TODO Need to decide image path here based on dimensions
        const dimensions = getScaledDimensions(image);
        const smallDim = Math.min(dimensions[0], dimensions[1]);      
        const imageSize = smallDim > smallThreshold ? 'large' : 'small';
    
        actualImg.src = 'images/' + imageSize + '/' + image.imageSrc;
    
        actualImg.onload = () => {
          actualImg.style.display = 'block';
        };
      };

      placeholderImg.src = 'images/tiny/' + image.imageSrc;
    }
  }  
}

function getParentColumnIndices (image: ImageModel, startIndex: number) {
  const parentColumnIndices = [];
  const columnSpan = Math.min(image.columnSpan, columnCount);

  for (let i = startIndex; i < startIndex + columnSpan; i++) {
    parentColumnIndices.push(i);
  }

  return parentColumnIndices;
}

function getLowestColumnIndex (parentColumnIndices: number[]) {
  let lowestColumnHeight = -Infinity;
  let lowestColumnIndex = -1;

  parentColumnIndices.forEach(function (i) {
    if (columnHeights[i] > lowestColumnHeight) {
      lowestColumnHeight = columnHeights[i];
      lowestColumnIndex = i;
    }
  });

  return lowestColumnIndex;
}

function updateParentColumnHeights (indices: number[], lowestIndex: number, height: number) {
  columnHeights[lowestIndex] += height;

  for (let i = 0; i < indices.length; i++) {
    const index = indices[i];
    columnHeights[index] = columnHeights[lowestIndex];
  }
}

function setImageStyles (
  element: HTMLElement, 
  imgContainer: HTMLDivElement,
  titleBlock: HTMLDivElement, 
  position: [number, number], 
  dimensions: [number, number]
) {
  element.style.left = position[0] + 'px';
  element.style.top = position[1] + 'px';
  element.style.width = dimensions[0] + 'px';
  element.style.height = dimensions[1] + titleBlockHeight + 'px';
  element.style.margin = itemMargin + 'px';

  imgContainer.style.width = dimensions[0] + 'px';
  imgContainer.style.height = dimensions[1] + 'px';

  titleBlock.style.top = dimensions[1] + 'px';
  titleBlock.style.height = titleBlockHeight + 'px';
}

function getScaledDimensions (image: ImageModel): [number, number] {
  const scale =  getImageWidth(image) / image.pixelDimensions[0];
  return [image.pixelDimensions[0] * scale, image.pixelDimensions[1] * scale];
}

function getPosition (startIndex: number, lowestIndex: number): [number, number] {
  const x = (startIndex * (columnWidth() + itemMargin * 2));
  const y = columnHeights[lowestIndex];

  return [x,y];
}

function getElementOuterHeight (imageHeight: number) {
  return imageHeight + titleBlockHeight + (itemMargin * 2);
}

// Will get images for the next row based on current columnCount
// And available image column spans.
function getRowImages (images: ImageModel[]) {
  const firstImage = images.shift();

  if (!firstImage) {
    return [];
  }

  const rowImages = [firstImage];
  let columnSpan = rowImages[0] ? rowImages[0].columnSpan : Infinity;

  if (columnSpan < columnCount) {

    const toRemove = [];

    // Add images to new rows array while summing colspan.
    // As soon colspan is equal to columnCount you have your row.
    for (let i = 0; i < images.length; i++) {
      const image = images[i];

      if (columnSpan + image.columnSpan <= columnCount) {
        rowImages.push(image);
        toRemove.push(i);

        columnSpan = columnSpan + image.columnSpan;
      }

      if (columnSpan === columnCount) {
        break;
      }
    }

    removeArrayIndices(images, toRemove);
  }

  return rowImages;
}

function getImageWidth (image: ImageModel) {
  const columnSpan = Math.min(image.columnSpan, columnCount);
  const marginSpan = (columnSpan - 1) * itemMargin * 2;
  return (columnWidth() * columnSpan) + marginSpan;
}

// The best row leaves the least white space on the top
// And has the least deviation on the bottom.
function getBestRow (rowImages: ImageModel[]) {

  const rowInfo = {
    topSpace: Infinity,
    deviation: Infinity
  };

  const rowPermutations = getArrayPermutations(rowImages);

  let bestRow = rowPermutations[0];

  rowPermutations.forEach(function (rowPermutation) {
    const _rowInfo = getRowInfo(rowPermutation);

    if (
      (_rowInfo.topSpace < rowInfo.topSpace) ||
      (_rowInfo.topSpace === rowInfo.topSpace &&
        _rowInfo.deviation < rowInfo.deviation)
    ) {

      bestRow = rowPermutation;

      rowInfo.topSpace = _rowInfo.topSpace;
      rowInfo.deviation = _rowInfo.deviation;
    }
  });

  return bestRow;
}

function getRowInfo (row: ImageModel[]) {
  let columnIndex = 0;
  let topSpace = 0;
  const potentialColumnHeights = columnHeights.slice();

  row.forEach(function (image) {
    const columnSpan = Math.min(image.columnSpan, columnCount);

    const endIndex = columnIndex + columnSpan;
    const columns = columnHeights.slice(columnIndex, endIndex);
    const lowestColumn = Math.max(...columns);
    const lowestColumnIndex = columnIndex + columns.indexOf(lowestColumn);

    columns.forEach(function (column) {
      topSpace += lowestColumn - column;
    });

    const scaledDimensions = getScaledDimensions(image);

    potentialColumnHeights[lowestColumnIndex] += scaledDimensions[1];

    for (let i = columnIndex; i < columnIndex + columnSpan; i++) {
      potentialColumnHeights[i] = potentialColumnHeights[lowestColumnIndex];
    }

    columnIndex += columnSpan;
  });

  const highestPotentialColumn = Math.min(...potentialColumnHeights);
  const lowestPotentialColumn = Math.max(...potentialColumnHeights);
  const deviation = lowestPotentialColumn - highestPotentialColumn;

  return { topSpace, deviation };
}

function removeArrayIndices (array: ImageModel[], indices: number[]) {
  for (let i = indices.length - 1; i >= 0; i--) {
    array.splice(indices[i], 1);
  }
}

function getArrayPermutations (inputArr: ImageModel[]) {
  const results: ImageModel[][] = [];

  const permute = (arr: ImageModel[], memo: ImageModel[] = []) => {
    let cur;

    for (let i = 0; i < arr.length; i++) {
      cur = arr.splice(i, 1);

      if (arr.length === 0) {
        results.push(memo.concat(cur));
      }

      permute(arr.slice(), memo.concat(cur));
      arr.splice(i, 0, cur[0]);
    }

    return results;
  };

  return permute(inputArr);
}
let oldLocation: string;

export function replaceLocation (newLocation: string) {
  if (newLocation === oldLocation) {
    return;
  }

  const detail = { oldLocation, newLocation };

  oldLocation = newLocation;

  window.history.replaceState({}, '', newLocation);

  const e = new CustomEvent('locationchange', { detail });
  window.dispatchEvent(e);
}

export function initializeLocation () {
  const pathParts = window.location.pathname.split('/');
  const detail = { newLocation: pathParts[ pathParts.length - 1 ] };

  const e = new CustomEvent('locationchange', { detail: detail });

  window.dispatchEvent(e);
}